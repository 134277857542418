import React from 'react'
import "./signUp.css"
import { Container, Form } from 'react-bootstrap'
import Header from '../header/header'
import Footer from '../footer/footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const SignUp = () => {
	return (
		<>
			<Header />
			<section className='signup-sec auth-page'>
				<Container fluid className='form-container'>
					<h2 className='mb-lg-5 mb-4 text-center'>Sign up</h2>
					<Form>
						<Form.Group className="input-wrapper mb-4">
							<Form.Label>Email*</Form.Label>
							<Form.Control type="email" placeholder="Enter Email" required />
						</Form.Group>
						<Form.Group className="input-wrapper mb-4">
							<Form.Label>Phone Number*</Form.Label>
							<Form.Control type="number" placeholder="Enter Phone Number" required />
						</Form.Group>
						<Form.Group className="input-wrapper mb-4">
							<Form.Label>Password*</Form.Label>
							<div className='position-relative'>
								<Form.Control type="password" placeholder="" required />
								<FontAwesomeIcon icon={faEye} className='eye-icon' />
							</div>
						</Form.Group>
						<Form.Group className="input-wrapper mb-4">
							<Form.Label>Confirm Password*</Form.Label>
							<div className='position-relative'>
								<Form.Control type="password" placeholder="" required />
								<FontAwesomeIcon icon={faEye} className='eye-icon' />
							</div>
						</Form.Group>
						<Link to="/email-verification" className='btn btn-main mt-2 w-100'>
							Get Started
						</Link>
					</Form>
				</Container>
			</section>
			<Footer />
		</>
	)
}

export default SignUp