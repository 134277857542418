import React from 'react'
import "./footer.css"

const Footer = () => {
    return (
        <footer className='footer-sec text-center'>
            <div className='container'>
                <p className='mb-0'>
                    Read <a href='#' className='text-black'>Terms & Condition</a> and <a href='#' className='text-black'>Privacy Policy</a>
                </p>
            </div>
        </footer>
    )
}

export default Footer