import React from 'react'
import { Image } from 'react-bootstrap'
import logo from "../../../assets/images/main-logo.svg"
import "./header.css"
import { Link } from 'react-router-dom'

const Header = () => {
	return (
		<header className='header-sec'>
			<div className="container">
				<div className="logo">
					<Link to="#" className='d-inline-block'>
						<Image src={logo} alt='logo' fluid />
					</Link>
				</div>
			</div>
		</header>
	)
}

export default Header