import React from 'react';
import "./sidebar.css";
import { Image } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from "../../../assets/images/main-logo.svg";
import iconDashboard from "../../../assets/images/icons/dashboard.svg";
import iconTeachers from "../../../assets/images/icons/user.svg";
import iconIntegration from "../../../assets/images/icons/setting.svg";
import iconTutorials from "../../../assets/images/icons/info.svg";
import iconSubscriptions from "../../../assets/images/icons/usd-circle.svg";
import iconBilling from "../../../assets/images/icons/usd-circle.svg";
import iconSupport from "../../../assets/images/icons/user-headset.svg";

const Sidebar = () => {

	const adminMenu = [
		{ id: 1, title: "Dashboard", icon: iconDashboard, path: "/admin/dashboard" },
		{ id: 2, title: "Teachers", icon: iconTeachers, path: "/admin/teachers" },
		{ id: 3, title: "Integration", icon: iconIntegration, path: "/admin/integration" },
		{ id: 4, title: "Tutorials", icon: iconTutorials, path: "/admin/tutorials" },
		{ id: 5, title: "Subscriptions", icon: iconSubscriptions, path: "/admin/subscriptions" },
		{ id: 6, title: "Billing", icon: iconBilling, path: "/admin/billing" },
		{ id: 7, title: "Support", icon: iconSupport, path: "/admin/support" }
	];

	return (
		<div className='sidebar'>
			<div className='header mb-5'>
				<Link to='/' className='d-inline-block'>
					<Image src={logo} alt='logo' fluid />
				</Link>
			</div>
			<ul className='sidebar-menu list-unstyled'>
				{adminMenu.map(item => (
					<li
						key={item.id}
					>
						<NavLink to={item.path}
							className={`menu-link d-flex align-items-center gap-3 fw-medium text-decoration-none ${({ isActive }) => isActive ? 'active' : ''}`}
						>
							<Image src={item.icon} alt='icon' fluid />
							{item.title}
						</NavLink>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Sidebar;