import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SignUp from './components/common/signUp/signUp';
import SignIn from './components/common/signIn/signIn';
import InfoForm from './components/admin/infoForm/infoForm';
import ResetPassword from './components/common/resetPassword/resetPassword';
import EmailVerification from './components/common/emailVerification/emailVerification';
import AboutUs from './components/common/aboutUs/aboutUs';
import Admin from './components/admin';
import Dashboard from './components/admin/dashboard/dashboard';
import Teachers from './components/admin/teachers/teachers';
import AddTeachers from './components/admin/teachers/addTeachers';

function App() {
	return (
		<div className="app-wrapper">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navigate to="/signin" />} />
					<Route path='/admin/*' element={<Admin />}>
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="teachers" element={<Teachers />} />
						<Route path='teachers/add' element={<AddTeachers />} />
					</Route>
					<Route path='/info' element={<InfoForm />} />
					<Route path="/signin" element={<SignIn />} />
					<Route path="/signup" element={<SignUp />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/email-verification" element={<EmailVerification />} />
					<Route path="/about-us" element={<AboutUs />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;