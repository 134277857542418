import React from 'react'
import Header from '../header/header'
import { Container, Form } from 'react-bootstrap'
import Footer from '../footer/footer'

const ResetPassword = () => {
    return (
        <>
            <Header />
            <section className='reset-sec auth-page'>
                <Container fluid className='form-container'>
                    <div className='heading-sec text-center mb-lg-5 mb-4'>
                        <h2 className='mb-3'>Forgot your password?</h2>
                        <p className='mb-0'>There is nothing to worry about, we'll send you a message to help you reset your password.</p>
                    </div>
                    <Form>
                        <Form.Group className="input-wrapper mb-4">
                            <Form.Label>Email*</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" required />
                        </Form.Group>
                        <button className='btn btn-main mt-2 w-100'>
                        Send Reset Link
                        </button>
                    </Form>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default ResetPassword