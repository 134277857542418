import React from 'react'
import Header from '../header/header'
import "./aboutUs.css"
import Footer from '../footer/footer'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

const AboutUs = () => {
    return (
        <>
            <Header />
            <section className='about-us-sec auth-page'>
                <Container fluid className='form-container'>
                    <div className='heading-sec text-center mb-lg-5 mb-4'>
                        <h2 className='mb-0'>How did you hear about us?</h2>
                    </div>
                    <div className='fields-wrapper'>
                        <label class="label-container mb-0">
                            Friend/Colleague
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            FSchool/Institution
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Social Media
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Words of Mouth
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Email Newsletter
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Conference/Event
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Blog/Article
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>

                        <label class="label-container mb-0">
                            Review Site
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Professional Association
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Educational Platform
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                        <label class="label-container mb-0">
                            Alumni Network
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div className='button-wrapper d-flex justify-content-between'>
                        <Link to='/admin/dashboard' className='btn btn-main'>
                            Next
                        </Link>
                        <Link to='/admin/dashboard' className='btn btn-outline'>
                            Skip
                        </Link>
                    </div>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default AboutUs