import React from 'react';
import { Outlet } from 'react-router-dom';
import { Form, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import profile from "../../assets/images/user-profile.png";
import Sidebar from '../common/sidebar/sidebar';
import "./style.css";

const Admin = () => {
    return (
        <>
            <Sidebar />
            <div className='main-dashboard'>
                <div className='searchbar-wrapper d-flex align-items-center justify-content-between'>
                    <Form className='searchbar-form'>
                        <Form.Group className="input-wrapper position-relative">
                            <FontAwesomeIcon icon={faSearch} />
                            <Form.Control type="text" placeholder='Search...' />
                        </Form.Group>
                    </Form>
                    <div className='user-profile d-flex align-items-center gap-3'>
                        <div className='image-wrapper'>
                            <Image src={profile} alt='profile image' fluid />
                        </div>
                        <strong className='name fw-medium'>Tim Cook</strong>
                    </div>
                </div>
                <Outlet />
            </div>
        </>
    );
};

export default Admin;
