import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const AddTeachers = () => {
  const navigate = useNavigate();

  return (
    <section className='teacher-content dashboard-content'>
      <button className='btn p-0 mb-4 d-flex align-items-center gap-2' onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
        Add Teacher
      </button>
      <h5 className='mb-4'>
        Personal Information
      </h5>
      <Form>
        <Row className='gy-4 gx-3'>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="" type="text" />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control placeholder="" type="text" />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="" type="text" />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control placeholder="" type="text" />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

export default AddTeachers