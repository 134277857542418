import React, { useState } from 'react'
import Header from '../../common/header/header'
import "./infoForm.css"
import { Col, Container, Form, Row } from 'react-bootstrap';
import Footer from '../../common/footer/footer';
import Select from 'react-select';
import { Link } from 'react-router-dom';


const InfoForm = () => {

	const [selectedMember, setSelectedMember] = useState(null);
	const [selectedStudent, setSelectedStudent] = useState(null);

	const memberOptions = [
		{ value: '1', label: '5-10' },
		{ value: '2', label: '20-30' },
		{ value: '3', label: '30-40' },
		{ value: '4', label: '40-50' },
	];
	const studentOptions = [
		{ value: '1', label: '10-50' },
		{ value: '2', label: '50-100' },
		{ value: '3', label: '100-200' },
		{ value: '4', label: '200-300' },
		{ value: '5', label: '300-400' },
	]

	return (
		<>
			<Header />
			<section className='info-sec auth-page'>
				<Container fluid className='form-container'>
					<h2 className='mb-lg-5 mb-4 text-center'>School Information</h2>
					<Form className='mb-5'>
						<Form.Group className="input-wrapper mb-3">
							<Form.Label>School name</Form.Label>
							<Form.Control type="text" placeholder="Enter School Name" />
						</Form.Group>
						<Row>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>No. of faculty member</Form.Label>
									<Select
										className='custom-select'
										defaultValue={selectedMember}
										onChange={setSelectedMember}
										options={memberOptions}
									// menuIsOpen 
									/>
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>No. of students</Form.Label>
									<Select
										className='custom-select'
										defaultValue={selectedStudent}
										onChange={setSelectedStudent}
										options={studentOptions}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className="input-wrapper mb-3">
							<Form.Label>School address</Form.Label>
							<Form.Control type="address" placeholder="Enter School Address" />
						</Form.Group>
						<Row>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>City</Form.Label>
									<Form.Select aria-label="Default select example">
										<option>Select</option>
										<option value="1">One</option>
										<option value="2">Two</option>
										<option value="3">Three</option>
									</Form.Select>
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>State</Form.Label>
									<Form.Select aria-label="Default select example">
										<option>Select</option>
										<option value="1">One</option>
										<option value="2">Two</option>
										<option value="3">Three</option>
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>Zip Code</Form.Label>
									<Form.Control type="address" placeholder="Enter School Address" />
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group className="input-wrapper mb-3">
									<Form.Label>Country</Form.Label>
									<Form.Select aria-label="Default select example">
										<option>Select</option>
										<option value="1">One</option>
										<option value="2">Two</option>
										<option value="3">Three</option>
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Link to="/signup" className='btn btn-main mt-4 w-100'>
							Continue
						</Link>
					</Form>
					<p className='text-center muted-text'>
						Already have an account?
						<Link to='/signin'> Sign in</Link>
					</p>
				</Container>
			</section>
			<Footer />
		</>
	)
}

export default InfoForm