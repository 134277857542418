import React from 'react'
import "./dashboard.css"
import { Col, Container, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

const Dashboard = () => {
    return (
        <>
            <section className='stats-sec'>
                <Container className='px-0' fluid>
                    <Row className='gx-0'>
                        <Col lg={3}>
                            <div className='box'>
                                <h6 className='heading'>All Faculty</h6>
                                <span className='number'>500</span>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='box'>
                                <h6 className='heading'>Active Faculty</h6>
                                <span className='number'>450</span>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='box'>
                                <h6 className='heading'>New Faculty</h6>
                                <span className='number'>20</span>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='box'>
                                <h6 className='heading'>Pending</h6>
                                <span className='number'>2</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='dashboard-content'>
                <div className='heading-wrapper d-flex align-items-center justify-content-between mb-3'>
                    <h6 className='mb-0'>Recent Faculty Registered</h6>
                    <div className='search-input'>
                        <input placeholder='Search' type='text' className='form-control' />
                    </div>
                </div>
                <div className='table-responsive'>
                    <Table borderless hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Assigned Classes</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>John Doe</td>
                                <td>john@example.com</td>
                                <td>8th Standard</td>
                                <td>
                                    <span className='status active'>
                                        Active
                                    </span>
                                </td>
                                <td>
                                    <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                        <span className='text-decoration-underline'>View Detail</span>
                                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>John Doe</td>
                                <td>john@example.com</td>
                                <td>O-Level</td>
                                <td>
                                    <span className='status inactive'>
                                        Inactive
                                    </span>
                                </td>
                                <td>
                                    <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                        <span className='text-decoration-underline'>View Detail</span>
                                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Michael Brown</td>
                                <td>michael@example.com</td>
                                <td>5th Standard</td>
                                <td>
                                    <span className='status active'>
                                        Active
                                    </span>
                                </td>
                                <td>
                                    <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                        <span className='text-decoration-underline'>View Detail</span>
                                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>John Doe</td>
                                <td>john@example.com</td>
                                <td>A-Level</td>
                                <td>
                                    <span className='status active'>
                                        Active
                                    </span>
                                </td>
                                <td>
                                    <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                        <span className='text-decoration-underline'>View Detail</span>
                                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </section>
        </>
    )
}

export default Dashboard