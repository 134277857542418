import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Nav, Tab, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Teachers = () => {
    return (
        <section className='teacher-content dashboard-content'>
            <div className='heading-wrapper bottom-line d-flex align-items-center justify-content-between pb-3 mb-3'>
                <h6 className='mb-0'>Teacher’s</h6>
                <Link to="/admin/teachers/add" className='btn btn-main'>
                    Add Teacher
                </Link>
            </div>
            <Tab.Container className="tabs-container" defaultActiveKey="all">
                <div className='nav-search-wrapper d-flex align-items-center justify-content-between mb-20'>
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="all">All</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="active">Active</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="inactive">
                                InActive
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className='search-input'>
                        <input placeholder='Search' type='text' className='form-control' />
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey="all">
                        <div className='table-responsive'>
                            <Table borderless hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned Classes</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>John Doe</td>
                                        <td>john@example.com</td>
                                        <td>8th Standard</td>
                                        <td>
                                            <span className='status active'>
                                                Active
                                            </span>
                                        </td>
                                        <td>
                                            <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                                <span className='text-decoration-underline'>View Detail</span>
                                                <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>John Doe</td>
                                        <td>john@example.com</td>
                                        <td>O-Level</td>
                                        <td>
                                            <span className='status inactive'>
                                                Inactive
                                            </span>
                                        </td>
                                        <td>
                                            <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                                <span className='text-decoration-underline'>View Detail</span>
                                                <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Michael Brown</td>
                                        <td>michael@example.com</td>
                                        <td>5th Standard</td>
                                        <td>
                                            <span className='status active'>
                                                Active
                                            </span>
                                        </td>
                                        <td>
                                            <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                                <span className='text-decoration-underline'>View Detail</span>
                                                <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>John Doe</td>
                                        <td>john@example.com</td>
                                        <td>A-Level</td>
                                        <td>
                                            <span className='status active'>
                                                Active
                                            </span>
                                        </td>
                                        <td>
                                            <div className='detail primary-dark-text d-flex align-items-center gap-2' role='button'>
                                                <span className='text-decoration-underline'>View Detail</span>
                                                <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="active">Second tab content</Tab.Pane>
                    <Tab.Pane eventKey="inactive">Third tab content</Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </section>
    )
}

export default Teachers