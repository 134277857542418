import React, { useState } from 'react'
import Header from '../header/header'
import { Container, Form } from 'react-bootstrap'
import "./emailVerification.css"
import Footer from '../footer/footer'
import { Link } from 'react-router-dom'

const EmailVerification = () => {
    const [code, setCode] = useState(Array(4).fill(''));

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // Move to the next input field
            if (e.target.nextSibling && value) {
                e.target.nextSibling.focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Code entered: ${code.join('')}`);
    };

    return (
        <>
            <Header />
            <section className='verification-sec auth-page'>
                <Container fluid className='form-container'>
                    <div className='heading-sec text-center mb-lg-5 mb-4'>
                        <h2 className='mb-3'>Verify your email</h2>
                        <p className='mb-0'>
                            We have sent you a code to your email. Enter it here to continue and create your account.
                        </p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="input-wrapper d-flex align-items-center justify-content-between mb-lg-5 mb-4">
                            {/* <Form.Label>Email*</Form.Label> */}
                            {/* <Form.Control type="email" placeholder="Enter Email" required /> */}
                            {code.map((digit, index) => (
                                <Form.Control
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleChange(e, index)}
                                    className="code-input"
                                />
                            ))}
                        </Form.Group>
                        <Link to='/about-us' className='btn btn-main mt-2 w-100'>
                            Verify
                        </Link>
                    </Form>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default EmailVerification