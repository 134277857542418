import React from 'react'
import Header from '../header/header'
import "./signIn.css"
import { Container, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Footer from '../footer/footer'
import { Link } from 'react-router-dom'

const SignIn = () => {
    return (
        <>
            <Header />
            <section className='login-sec auth-page'>
                <Container fluid className='form-container'>
                    <h2 className='mb-lg-5 mb-4 text-center'>Log in</h2>
                    <Form className='mb-5'>
                        <Form.Group className="input-wrapper mb-4">
                            <Form.Label>Email*</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" required />
                        </Form.Group>
                        <Form.Group className="input-wrapper mb-4">
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Form.Label className='mb-0'>Password*</Form.Label>
                                <Link to="/reset-password" className='fw-medium'>Forgot password?</Link>
                            </div>
                            <div className='position-relative'>
                                <Form.Control type="password" placeholder="" required />
                                <FontAwesomeIcon icon={faEye} className='eye-icon' />
                            </div>
                        </Form.Group>
                        <Link to="/admin/dashboard" className='btn btn-main mt-2 w-100'>
                            Sign in
                        </Link>
                    </Form>
                    <p className='text-center muted-text'>
                        Not a member?
                        <Link to='/info'> Start a 14 day free trial</Link>
                    </p>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default SignIn